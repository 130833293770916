<template>
    <page-container :title="breadcrumb" :breadcrumb="breadcrumb">
      <div class="bg-white">
        <div class="main-container" style="margin-top: -15px">
          <div class="title">远程管理</div>
        </div>
      </div>
      <div class="container">
        <div class="table-btns" style="margin-top: 2%">
          <a-button type="primary" size="large" @click="getOrSet" style="margin-left: 80%">
            {{status ?'下发当前参数':'获取最新参数' }}</a-button>
          <a-icon type="redo" class="after-btn table-refresh" @click="getParamsConfig" style="margin-left: 1%;font-size: 20px"></a-icon>
        </div>
        <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns"  :label-col="{span:9}" :wrapper-col="{span:14}" style="margin-top: 20px">
          <a-form-model-item label="设备编码" prop="devicenum">
            <a-input v-model="devicenum" placeholder="请输入设备编码"></a-input>
          </a-form-model-item>
          <a-form-model-item label="模块类型" prop="modeltype">
            <a-input v-model="modeltype" placeholder=""></a-input>
          </a-form-model-item>
        </a-form-model>
        <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns"  :label-col="{span:9}" :wrapper-col="{span:14}" style="margin-top: 1%">
          <a-form-model-item label="远程端口" prop="FRP_port">
            <a-input v-model="mqttConfigs.FRP_port" placeholder=""></a-input>
          </a-form-model-item>
          <a-form-model-item label="远程端口使能" prop="FRP_enable">
            <a-input v-model="mqttConfigs.FRP_enable" placeholder=""></a-input>
          </a-form-model-item>
          <a-form-model-item label="远程服务器地址" prop="FRP_server_addr">
            <a-input v-model="mqttConfigs.FRP_server_addr" placeholder=""></a-input>
          </a-form-model-item>
          <!--      <a-form-model-item label="模式参数" prop="Manufacture_mode">-->
          <!--        <a-input v-model="iotConfigs.Manufacture_mode" placeholder=""></a-input>-->
          <!--      </a-form-model-item>-->
<!--          <a-form-model-item label="重启参数" prop="Reboot">-->
<!--            <a-input v-model="iotConfigs.Reboot" placeholder=""></a-input>-->
<!--          </a-form-model-item>-->
        </a-form-model>
        <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns"  :label-col="{span:14}" :wrapper-col="{span:10}" style="margin-top: 1%">
          <a-form-model-item label="重启StreamServer服务器的流媒体服务" prop="Reboot">
            <a-button type="primary" size="large" @click="rebootSRS" style="margin-left: 0%;height: 30px">重启服务</a-button>
          </a-form-model-item>
          <a-form-model-item label="重启StreamServer服务器" prop="Reboot">
            <a-button type="primary" size="large" @click="rebootSRSServer" style="margin-left: 0%;height: 30px">重启服务器</a-button>
          </a-form-model-item>
          <a-form-model-item label="重启ESPServer服务器的物联网平台服务" prop="Reboot">
            <a-button type="primary" size="large" @click="rebootPlat" style="margin-left: 0%;height: 30px">重启服务</a-button>
          </a-form-model-item>
          <a-form-model-item label="重启ESPServer服务器" prop="Reboot">
            <a-button type="primary" size="large" @click="rebootESPServer" style="margin-left: 0%;height: 30px">重启服务器</a-button>
          </a-form-model-item>
        </a-form-model>
      </div>
    </page-container>
</template>
<script>
import {
  getDeviceparamChildLisByParentId,
  getDeviceparamValueList, serverReboot,
  setDeviceparamValueList, shutdownServer,
} from "A/ai";
export default {
  name: "remoteManagement",
  data() {
    return {
      breadcrumb: [
        {
          name: '系统配置',
          path: ''
        },
        {
          name: '高级设置',
          path: ''
        },
        {
          name: '远程管理',
          path: ''
        },
      ],
      mqttConfigs:{
        FRP_port:"",
        FRP_enable:"",
        FRP_server_addr:"",
      },
      devicemoduletype: '',
      devicenum: '',
      modeltype:'1',
      modelnum: '',
      methodList: [],
      tabKey: '20',
      status:false,
    }
  },
  watch: {
    devicemoduletype(val) {
      if (val) {
        this.getMethods();
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.devicemoduletype = this.$route.params.devicemoduletype||'1';
      this.devicenum = this.$route.params.devicenum||'';
      this.modelnum = this.$route.params.modelnum||'';
    },
    getMethods() {
      this.methodList = [];
      this.tabKey = '20';
      this.paramsList = [];
      let params = {
        parentid: this.devicemoduletype
      };
      this.showLoading();
      getDeviceparamChildLisByParentId(params).then(res => {
        this.hideLoading();
        if(res && res.returncode == '0') {
          this.methodList = res.item||[];
        }
      }).catch(err => {
        this.hideLoading();
      })
    },
    getParamsConfig() {
      this.paramsList = [];
      let params = {
        devicenum: this.devicenum,
        data: [
          {
            modeltype: this.devicemoduletype,
            modelnum: this.modelnum,
            params: {
              methord: 'GET',
              url: '/mqttclient',
            }
          }
        ]
      };
      getDeviceparamValueList(params).then(res => {
        this.hideLoading();
        if(res && res.returncode == '0' && res.item && res.item.length && res.item[0].params && res.item[0].params.body) {
          this.mqttConfigs.FRP_port=res.item[0].params.body.FRP_port
          this.mqttConfigs.FRP_enable=res.item[0].params.body.FRP_enable
          this.mqttConfigs.FRP_server_addr=res.item[0].params.body.FRP_server_addr
        }
      }).catch(err => {
        this.hideLoading();
      })
      // }
    },
    setParamsConfig(){
      // let selectedMethod = getItemFromArrayByKey(this.methodList, 'devparamcfgid', this.tabKey);
      // if(selectedMethod) {
      let paramsBody = {
        FRP_port: this.mqttConfigs.FRP_port,
        FRP_enable: this.mqttConfigs.FRP_enable,
        FRP_server_addr: this.mqttConfigs.FRP_server_addr,
      };
      let params = {
        devicenum: this.devicenum,
        data: [
          {
            modeltype: this.devicemoduletype,
            modelnum: this.modelnum,
            params: {
              methord: 'PUT',
              url:'/mqttclient',
              body: paramsBody,
            }
          }
        ]
      };
      this.showLoading();
      setDeviceparamValueList(params).then(res => {
        this.hideLoading();
        if(res && res.returncode == '0') {
          this.$message.success('操作成功');
        }else {
          this.$message.error(res.errormsg||'操作失败');
        }
      }).catch(err => {
        this.hideLoading();
        this.$message.error('操作失败');
      })
      // }
    },
    getOrSet(){
      if(this.status){
        this.setParamsConfig();
        this.status=false;
      }else{
        this.getParamsConfig();
        this.status=true
      }
    },
    rebootSRS(){
      let that=this;
      this.$confirm('如果您确定需要重启，请单击确认键以完成重启。',{
        title: '您确定要重启服务/服务器吗?',
        type:'warning',
        centered:true,
      }).then(()=>{
        return new Promise((resolve, reject) => {
          setTimeout(Math.random() > 0.5 ? resolve : reject, 500);
          let params={
            url:"183.214.195.130:7090"
          }
          serverReboot(params).then(res=>{
            if(res&&res.returncode=='0'){
              that.$message.success("服务即将重启，请耐心等待！")
            }
          })
        }).catch(() => console.log('Oops errors!'));
      }).catch(()=>{
      });
    },
    rebootSRSServer(){
      let that=this;
      this.$confirm('如果您确定需要重启，请单击确认键以完成重启。',{
        title: '您确定要重启服务/服务器吗?',
        type:'warning',
        centered:true,
      }).then(()=>{
        return new Promise((resolve, reject) => {
          setTimeout(Math.random() > 0.5 ? resolve : reject, 500);
          let params={
            url:"183.214.195.130:7090"
          }
          shutdownServer(params).then(res=>{
            if(res&&res.returncode=='0'){
              that.$message.success("服务器将在三分钟后重启，请耐心等待！")
            }
          })
        }).catch(() => console.log('Oops errors!'));
      }).catch(()=>{
      });
    },
    rebootPlat(){
      let that=this;
      this.$confirm('如果您确定需要重启，请单击确认键以完成重启。',{
        title: '您确定要重启服务/服务器吗?',
        type:'warning',
        centered:true,
      }).then(()=>{
        return new Promise((resolve, reject) => {
          setTimeout(Math.random() > 0.5 ? resolve : reject, 500);
          let params={
            url:"183.214.195.130:7091"
          }
          serverReboot(params).then(res=>{
            if(res&&res.returncode=='0'){
              that.$message.success("服务即将重启，请耐心等待！")
            }
          })
        }).catch(() => console.log('Oops errors!'));
      }).catch(()=>{
      });
    },
    rebootESPServer(){
      let that=this;
      this.$confirm('如果您确定需要重启，请单击确认键以完成重启。',{
        title: '您确定要重启服务/服务器吗?',
        type:'warning',
        centered:true,
      }).then(()=>{
        return new Promise((resolve, reject) => {
          setTimeout(Math.random() > 0.5 ? resolve : reject, 500);
          let params={
            url:"183.214.195.130:7091"
          }
          shutdownServer(params).then(res=>{
            if(res&&res.returncode=='0'){
              that.$message.success("服务器将在三分钟后重启，请耐心等待！")
            }
          })
        }).catch(() => console.log('Oops errors!'));
      }).catch(()=>{
      });
    }
  }
}
</script>
<style scoped>
.bg-white {
  background-color: #fff;
}
.container{
  background-color: #fff;
  margin-left: 1%;
  margin-right: 1%;
  margin-top: 1%;
  flex-grow: 1;
  overflow: auto;
  height: 730px;
}
.title {
  font-weight: bold;
  font-size: 20px;
  padding: 5px 20px;
}
.main-container {
  display: flex;
  padding: 10px;
}

</style>